import request from "../request";


export const $adminLogin = (params) =>{  // 登陆接口
    return request({
        url:"/api/admin/login",
        method:'post',
        data:params
    })
}

export const $channelInfo = (data) =>{ // 渠道好信息 价格 接口
    return request({
        url:"/api/channel/info",
        method:'get',
        params:data
    })
}


export const $adminCurrentUser = (data) =>{  // 获取用户信息
    return request({
        url:"/api/admin/current_user",
        method:'get',
        headers:{Authorization:`Bearer ${window.localStorage.getItem('tokenKey')}`},
        params:data
    })
}

export const $adminDictList = (data) =>{  // 获取下拉列表数据
    return request({
        url:"/api/admin/dict/list",
        method:'get',
        headers:{Authorization:`Bearer ${window.localStorage.getItem('tokenKey')}`},
        params:data
    })
}

export const $adminDictSave = (params) =>{  // 修改下拉列表数据
    return request({
        url:"/api/admin/dict/save",
        method:'post',
        headers:{Authorization:`Bearer ${window.localStorage.getItem('tokenKey')}`},
        data:params
    })
}

export const $adminPageAdConfigSave = (params) =>{  // 保存设置广告位接口
    return request({
        url:"/api/admin/page_ad_config/save",
        method:'post',
        headers:{Authorization:`Bearer ${window.localStorage.getItem('tokenKey')}`},
        data:params
    })
}

export const $adminPageAdConfigInfo = (data) =>{  // 获取广告位接口
    return request({
        url:"/api/admin/page_ad_config/info",
        method:'get',
        headers:{Authorization:`Bearer ${window.localStorage.getItem('tokenKey')}`},
        params:data
    })
}

export const $adminChannelSave = (params) =>{  //  创建修改渠道好接口
    return request({
        url:"/api/admin/channel/save",
        method:'post',
        headers:{Authorization:`Bearer ${window.localStorage.getItem('tokenKey')}`},
        data:params
    })
}

export const $adminChannelList = (data) =>{  //  获取渠道列表
    return request({
        url:"/api/admin/channel/list",
        method:'get',
        headers:{Authorization:`Bearer ${window.localStorage.getItem('tokenKey')}`},
        params:data
    })
}

export const $adminChannelInfo = (data) =>{  //  渠道号详情
    return request({
        url:"/api/admin/channel/info",
        method:'get',
        headers:{Authorization:`Bearer ${window.localStorage.getItem('tokenKey')}`},
        params:data
    })
}

export const $adminOrderList = (data) =>{  //  获取订单列表
    return request({
        url:"/api/admin/order/list",
        method:'get',
        headers:{Authorization:`Bearer ${window.localStorage.getItem('tokenKey')}`},
        params:data
    })
}

export const $adminOrderDownloadList = (data) =>{  //  获取订单列表
    return request({
        url:"/api/admin/order/download_list",
        method:'get',
        headers:{Authorization:`Bearer ${window.localStorage.getItem('tokenKey')}`},
        responseType:"blob",
        params:data
    })
}

export const $adminConfigGetGlobalConfig = (data) =>{  //  获取全局配置
    return request({
        url:"/api/admin/config/get_global_config",
        method:'get',
        headers:{Authorization:`Bearer ${window.localStorage.getItem('tokenKey')}`},
        params:data
    })
}

export const $adminConfigSaveGlobalConfig = (params) =>{  //  修改全局配置
    return request({
        url:"/api/admin/config/save_global_config",
        method:'post',
        headers:{Authorization:`Bearer ${window.localStorage.getItem('tokenKey')}`},
        data:params
    })
}

export const $adminConfigGetHostConfig = (data) =>{  //  获取全局配置的域名列表
    return request({
        url:"/api/admin/config/get_host_config",
        method:'get',
        headers:{Authorization:`Bearer ${window.localStorage.getItem('tokenKey')}`},
        params:data
    })
}

export const $adminConfigSaveHostConfig = (params) =>{  //  修改全局配置的域名列表
    return request({
        url:"/api/admin/config/save_host_config",
        method:'post',
        headers:{Authorization:`Bearer ${window.localStorage.getItem('tokenKey')}`},
        data:params
    })
}
export const $adminOrderDoAdCallback = (params) =>{  //  订单多回传
    return request({
        url:"/api/admin/order/do_ad_callback",
        method:'post',
        headers:{Authorization:`Bearer ${window.localStorage.getItem('tokenKey')}`},
        data:params
    })
}

export const $adminPayGroupConfigList = (data) =>{  //  支付分组数据
    return request({
        url:"/api/admin/pay_group_config/list",
        method:'get',
        headers:{Authorization:`Bearer ${window.localStorage.getItem('tokenKey')}`},
        params:data
    })
}

export const $adminPayGroupConfigSave = (params) =>{  //  修改支付分组
    return request({
        url:"/api/admin/pay_group_config/save",
        method:'post',
        headers:{Authorization:`Bearer ${window.localStorage.getItem('tokenKey')}`},
        data:params
    })
}


//代理商接口
export const $adminCompanyList = (data) =>{  // 获取代理商列表
    return request({
        url:"/api/admin/company/list",
        method:'get',
        headers:{Authorization:`Bearer ${window.localStorage.getItem('tokenKey')}`},
        params:data
    })
}
export const $adminCompanySave = (params) =>{  //  代理商新建/修改
    return request({
        url:"/api/admin/company/save",
        method:'post',
        headers:{Authorization:`Bearer ${window.localStorage.getItem('tokenKey')}`},
        data:params
    })
}
export const $adminCompanyInfo = (data) =>{  //  代理商详情
    return request({
        url:"/api/admin/company/info",
        method:'get',
        headers:{Authorization:`Bearer ${window.localStorage.getItem('tokenKey')}`},
        params:data
    })
}


//代理商用户接口
export const $adminCompanyUserSave = (params) =>{  //  代理商用户新建/修改
    return request({
        url:"/api/admin/company/user_save",
        method:'post',
        headers:{Authorization:`Bearer ${window.localStorage.getItem('tokenKey')}`},
        data:params
    })
}
export const $adminCompanyUserList = (data) =>{  // 获取代理商用户列表
    return request({
        url:"/api/admin/company/user_list",
        method:'get',
        headers:{Authorization:`Bearer ${window.localStorage.getItem('tokenKey')}`},
        params:data
    })
}
export const $adminCompanyUserInfo = (data) =>{  //  代理商用户详情
    return request({
        url:"/api/admin/company/user_info",
        method:'get',
        headers:{Authorization:`Bearer ${window.localStorage.getItem('tokenKey')}`},
        params:data
    })
}

// 微信客服接口

export const $adminWechatworkRefresh = (params) =>{  //  微信客服地址刷新
    return request({
        url:"/api/admin/wechatwork/refresh",
        method:'post',
        headers:{Authorization:`Bearer ${window.localStorage.getItem('tokenKey')}`},
        data:params
    })
}
export const $adminWechatworkSave = (params) =>{  //  微信客服地址新建/修改
    return request({
        url:"/api/admin/wechatwork/save",
        method:'post',
        headers:{Authorization:`Bearer ${window.localStorage.getItem('tokenKey')}`},
        data:params
    })
}
export const $adminWechatworkList = (data) =>{  // 微信客服列表
    return request({
        url:"/api/admin/wechatwork/list",
        method:'get',
        headers:{Authorization:`Bearer ${window.localStorage.getItem('tokenKey')}`},
        params:data
    })
}
export const $adminWechatworkInfo = (data) =>{  //  微信客服详情
    return request({
        url:"/api/admin/wechatwork/info",
        method:'get',
        headers:{Authorization:`Bearer ${window.localStorage.getItem('tokenKey')}`},
        params:data
    })
}

// 产品文案接口
export const $adminCopyWritingSave = (params) =>{  //  产品文案新建/修改
    return request({
        url:"/api/admin/copywriting/save",
        method:'post',
        headers:{Authorization:`Bearer ${window.localStorage.getItem('tokenKey')}`},
        data:params
    })
}
export const $adminCopyWritingList = (data) =>{  //  产品文案列表
    return request({
        url:"/api/admin/copywriting/list",
        method:'get',
        headers:{Authorization:`Bearer ${window.localStorage.getItem('tokenKey')}`},
        params:data
    })
}
export const $adminCopywritingInfo = (data) =>{  //   产品文案详情
    return request({
        url:"/api/admin/copywriting/info",
        method:'get',
        headers:{Authorization:`Bearer ${window.localStorage.getItem('tokenKey')}`},
        params:data
    })
}

// 投诉complaint
export const $adminComplaintList = (data) =>{  // 投诉列表
    return request({
        url:"/api/admin/complaint/list",
        method:'get',
        headers:{Authorization:`Bearer ${window.localStorage.getItem('tokenKey')}`},
        params:data
    })
}

export const $adminComplaintDownloadList = (data) =>{  //  下载投诉表格
    return request({
        url:"/api/admin/complaint/download_list",
        method:'get',
        headers:{Authorization:`Bearer ${window.localStorage.getItem('tokenKey')}`},
        responseType:"blob",
        params:data
    })
}
