import { configureStore } from "@reduxjs/toolkit"
import createListReducer from "./reducer/createListReducer"
import orderListReducer from "./reducer/orderListReducer"
import agentListReducer from "./reducer/agentListReducer"
import agentUserListReducer from "./reducer/agentUserListReducer"
import complaintListReducer from "./reducer/complaintListReducer"
import productCopyWriterListReducer from "./reducer/productCopyWriterListReducer"

export default configureStore({
    reducer:{
        createListReducer,
        orderListReducer,
        agentListReducer,
        agentUserListReducer,
        complaintListReducer,
        productCopyWriterListReducer
    }
})