import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    currentPage:1
}
const productCopyWriterListReducer = createSlice({
    name:'productCopyWriterListReducer',
    initialState,
    reducers:{
        setCurrentPage(state,action) { // 修改页数
            state.currentPage = action.payload
        }
    }
})

export const { setCurrentPage } = productCopyWriterListReducer.actions

export default productCopyWriterListReducer.reducer